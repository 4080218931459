import { Component, OnInit } from '@angular/core';
import { EngageService } from '../engage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-engage-landing',
  templateUrl: './engage-landing.component.html',
  styleUrls: ['./engage-landing.component.css']
})
export class EngageLandingComponent implements OnInit {

  subscription: Subscription;
  orgs: any[];
  sites: any[]; // orgs sites
  siteList: SiteListItem[] = []; // Site Only list
  orgFilter: any;

  constructor(private engageService: EngageService) {
    this.subscription = this.engageService
      .processedData
      .subscribe(data => {
        if (!data) {
          return;
        }
        console.log(`PROCESSED`, data);
        this.orgs = data[0];
        this.sites = data[3];

        /*
         * Create a list of sites in organisations
         */
        this.orgFilter = [];
        this.siteList = [];
        this.sites.forEach(site => {
          const siteListItem = this.siteList.find(sl => sl.id === site.siteId);
          // (up.createdAt,'|', up.path, '|',up.site_id,'|',up.user_id,'|',u.name,'|',u.org_id)
          const sitePathComponents: any[] = (site.path ? site.path.split('|') : []);
          let [accessedAt, path, siteId, userId, userName, orgId] = sitePathComponents;

          accessedAt = accessedAt ? new Date(accessedAt) : null;
          const org = this.orgs.find(orgItem => orgItem.orgPartnershipId === +orgId);

          if (!siteListItem) {
            this.siteList.push({ id: site.siteId, title: site.siteTitle, accessedAt, org, path: site.path, userName, userId });
          } else {
            if (+siteListItem.accessedAt < +accessedAt) {
              siteListItem.accessedAt = accessedAt;
              siteListItem.org = org;
              siteListItem.path = site.path;
              siteListItem.userName = userName;
              siteListItem.userId = userId;
            }
          }
          let exists = this.orgFilter.find(of => of.id === site.sharedOrgId);
          if (!exists) {
            this.orgFilter.push({ id: site.sharedOrgId, title: site.sharedOrgTitle, isCollapsed: false, isCompleted: false, sites: [] });
            exists = this.orgFilter[this.orgFilter.length - 1];
          }
          exists.sites.push({ id: site.siteId, title: site.siteTitle, accessCount: 0 });
        });

        data[1].forEach(row => {
          const { orgId, siteId } = row;
          try {
            const org = this.orgFilter.find(orgf => orgf.id === orgId);
            const site = org.sites.find(sitef => sitef.id === siteId);
            site.accessCount += 1;
          } catch (e) {
            // Org may of been removed from partnership
            console.log(e);
          }
        });

        // Mark orgs as completed or not for views accross sites
        this.orgFilter.forEach(org => {
          org.isCompleted = !(org.sites.find(site => site.accessCount === 0));
          org.isCollapsed = org.isCompleted;
          org.sites.forEach(site => {
            const siteObj = this.siteList.find(sitef => sitef.id === site.id && site.accessCount > 0);
            if (!siteObj) {
              console.log('CANT FIND SITE', site);
            } else {
              siteObj.isViewed = true;
            }
          });
        });

        this.sortAndFilter();
      });
  }

  ngOnInit() {
    console.log('INIT');
  }

  sortAndFilter() {
    this.siteList.sort((a, b) => a.title > b.title ? 1 : -1);
  }
}

export interface SiteListItem {
  id: number;
  title: string;
  accessedAt: Date;
  isViewed?: boolean;
  org: any;
  path?: string;
  userName?: string;
  userId?: number;
}
