import { Component, ElementRef, ViewChild, AfterViewInit, HostListener, VERSION } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { APIService } from './shared/api.service';
import { ClipboardService } from './shared/clipboard.service';
import { StoreService, ThemeType } from './shared/store.service';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { User } from './classes/user';
import { Session } from './classes/session';
import { Org } from './classes/org';
import { Ticket } from './classes/ticket';
import { filter } from 'rxjs/operators';
import { Theme } from './classes/theme';
import { BuildingsService } from './shared/buildings.service';
import { AssetType } from './classes/asset';
import { AssetService } from './shared/asset.service';
// @ts-ignore
import { build } from '../assets/build/build';
import { PageOption } from './shared/page.service';
import { MenuItem } from 'primeng/api';
import { SessionService } from './shared/session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

    @ViewChild('rootContainer', { static: true })
    rootContainer: ElementRef;

    @ViewChild('alarmCountRef')
    alarmCountRef: ElementRef;

    @ViewChild('issueCountRef')
    issueCountRef: ElementRef;

    ticketsPinned: Ticket[];
    appVersion: string;
    ngVersion = VERSION.full;
    buildDate = build;
    filtering: any; // Column 2
    engaging: boolean; // Column 2
    title = 'Dashboard';
    isScrolled = false;
    changePosition: number = 100;
    currentPosition: number = 0;
    user: User = new User();
    // Users Org
    org: Org;
    session: Session;
    authorised = false;
    showMenus: boolean;
    isFullscreen: boolean;

    clipboard: any;
    onLogin: boolean;
    moduleAccess: any = {};
    isLoading = false;
    timerSubscription: any;
    theme: ThemeType = 'light';
    restricted: boolean;
    alarmCount = 0;
    issueCount = 0;
    backgroundCount = 0;
    // Page options for top right menu
    pageOptions: PageOption[];

    isShowingMobileMenu: boolean;
    domain: string;
    subdomain: string;
    hasSeenNewFeature: boolean;
    roles: { offline_assets: boolean } = { offline_assets: false };
    logo: string;

    adminMenu: MenuItem[];
    isLeftMenuExpanded: boolean;
    isAdmin: boolean;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(target) { this.updateHeader(target) }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'F8') {
            this.themeToggle();
        }
    };

    // tslint:disable-next-line: max-line-length
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private apiService: APIService, locationStrategy: LocationStrategy, location: Location, platformLocation: PlatformLocation, private clipboardService: ClipboardService, private storeService: StoreService, private buildingService: BuildingsService, private assetService: AssetService, private sessionService: SessionService) {

        storeService.theme.pipe(filter(theme => theme != null)).subscribe(theme => this.theme = theme);
        this.storeService.setTheme();
        this.appVersion = APIService.VERSION;
        apiService.appVersion = APIService.VERSION;;
        this.subdomain = APIService.SUBDOMAIN;

        console.log(`%c
                                                       /(/,.
                                                       /(((((((((,
                                                              *((((((
                                                       /(((((.    (((((
                                                        ./((((((/   /(((/
                                                              ((((/   ((((
                                                       /((((,   ((((   (((*
                                                       /((((((  .(((,  ((((
                      #######,      /############/
                    ##########     ##################.
                  /###########     ######      *#######
                .#####  ######     ######        .######
               #####    ######     ######         ######.
             ###################   ######         ######
             ###################   ######       ,######/
                        ######     ###################
                        ######     ###############  %c Version ${this.appVersion}
        `, 'color: lightgrey', 'color: red');

        if (APIService.SUBDOMAIN !== '4dml') {
            this.showMenus = true;
        }
        const l = locationStrategy;
        if (l.path().indexOf('/login') !== -1) {
            // On the login screen never set the state to authorised.
            this.authorised = false;
            this.onLogin = true;
        } else {
            this.onLogin = false;
        }

        const lmSettingsString = localStorage.getItem('lm:settings');
        if (lmSettingsString) {
            const lmSettings = JSON.parse(lmSettingsString);
            storeService.setMenuAsText(lmSettings.isTextMenu);
        }

        setTimeout(() => {
            storeService.pageOptions.subscribe(options => this.pageOptions = options);
        }, 10);


        storeService.menuAsText.subscribe(setting => {
            this.isLeftMenuExpanded = (setting === null) || setting;
        });

        apiService.loggedIn.subscribe(
            user => {
                console.log('loggedIn next()');
                // Turn off any "On login page" effects
                this.adminMenu = null;
                this.alarmCount = 0;
                if (user === null) {
                    if (this.timerSubscription) {
                        this.timerSubscription.unsubscribe();
                        this.timerSubscription = null;
                    }

                } else if (user && this.onLogin) {
                    this.onLogin = false;
                    this.startTimers();
                    this.buildAdminMenu();
                }
            });

        apiService.fullscreen.subscribe(fullscreen => {
            console.log('fullscreen next()');
            this.isFullscreen = fullscreen;
        });

        apiService.user.subscribe(
            user => {
                console.log('** SEEN ', user);
                this.moduleAccess = this.apiService.moduleAccess;
                this.restricted = false;
                this.adminMenu = null;
                if (user && user.email && user.org) {
                    this.user = user;
                    this.isAdmin = this.apiService.isAdmin();
                    this.org = user.org;
                    this.title = user.org.title;
                    this.authorised = true;
                    this.apiService.getAllTypes()
                        .then(types => {
                            this.assetService.setAssetTypes(types[0].map(type => new AssetType(type)));
                        });
                    this.restricted = user.isRestricted;
                    if (!this.onLogin) {
                        // Start timers if user refreshed page or is already logged in
                        this.startTimers();
                    }
                    // Apply Org Theme
                    if (user.org.settings) {
                        const theme = new Theme(user.org.settings);
                        theme.updateDOM();
                        this.logo = user.org.settings.theme_logo;
                    }

                    this.roles.offline_assets = this.apiService.hasOrgRole('offline_assets') || this.apiService.isAdmin();
                    setTimeout(() => {
                        this.apiService.getOrgs('access=1')
                            .then(orgs => {
                                this.storeService.setOrgs(orgs);

                            });
                        this.buildAdminMenu();
                    }, 1);

                } else {
                    this.user = null;
                    this.authorised = false;
                    this.storeService.setOrgs([]);

                    console.log('ON LOGIN = ', this.onLogin, l.path());
                    if (l.path().indexOf('/login') !== -1) {
                        this.onLogin = true;
                        console.log('now at login');
                    } else if (l.path().indexOf('/token/') === -1) {
                        const defaultTo = localStorage.getItem('route:unauth') || '/login';
                        this.router.navigate([defaultTo]);
                    } else {
                        console.log('token received, no user required');
                    }
                }
            }
        );

        sessionService.session.subscribe(session => {
            if (this.isAdmin) {
                console.log('session next()', session);
            }
            if (session && session.token) {
                this.session = session;
                if (this.onLogin) {
                    // On the login screen never set the state to authorised.
                    this.authorised = false;
                } else {
                    this.authorised = (session.token !== '');
                }
                if (this.authorised && this.user) {
                    console.log('is authorised');
                    this.buildingService.getBuildings().then((buildings) => console.log(`received ${buildings?.length} buildings`));
                }
            } else {
                // Can't be logged in without a session
                this.authorised = false;
            }
        });

        // Subscripe to clipboard copy
        clipboardService.clipboard.subscribe(event => {
            console.log('clipboard next()');
            if (!event) {
                return;
            }
            this.clipboard = event;
        });

        storeService.isLoading.subscribe(state => {
            // The page loading state has changed
            console.log('isloading next()');
            setTimeout(() => {
                this.isLoading = state;
            });

        });

        storeService.filtering.subscribe(filtering => {
            console.log('filtering next()');
            this.filtering = filtering;
        });

        storeService.dataChanged.pipe(filter(data => data !== null && data.action !== 'changed')).subscribe(data => {
            // When data was updated from a component get fresh notifications
            // Changes from notifications are under action 'changed'
            // Changes locally are 'created', 'deleted', 'updated'
            console.log('Data changed', data.target, data.action, data);
            this.getNotifications();
        });

        activatedRoute.url.subscribe(url => {
            console.log('ROUTE', url);
        });
    }

    buildAdminMenu() {

        setTimeout(() => {
            if (this.user.role !== 'admin' || this.adminMenu) {
                return;
            }
            this.apiService.getOrgs().then(orgs => {

                const orgSwaps = orgs.filter(o => o.swapCount)
                    .sort((a, b) => a.swapCount < b.swapCount ? 1 : -1)
                    .sort((a, b) => a.swapUpdatedAt < b.swapUpdatedAt ? 1 : -1)
                    .reverse();

                this.adminMenu = [
                    {
                        label: 'Use 4D logo', icon: 'mdi mdi-image', command: (event) => {
                            this.logo = '4d-logo.png';
                        }
                    },
                    {
                        label: 'Swap org', icon: 'mdi mdi-swap-horizontal', items: orgSwaps.map(o => {
                            return {
                                label: o.shortTitle, id: String(o.id),
                                command: (event) => {
                                    this.apiService.switchOrg(+event.item.id).then((result) => {
                                        if (result) {
                                            this.router.navigate(['/login']);
                                        }
                                    });
                                }
                            }
                        })
                    }
                ];
            });
        }, 100);
    }

    getNotifications() {
        const lastReadAt = this.apiService.lastNotificationRead;
        const lastNotification = this.apiService.lastNotification;
        this.apiService
            .getNotifications()
            .then(notifications => {
                if (!notifications) {
                    return;
                }
                const counts = {
                    issues: { new: 0, active: 0, resolved: 0 },
                    alarms: { new: 0, acknowledged: 0 }
                };
                notifications[0].map(noti => {
                    counts.alarms[noti.state] = noti.count;
                });
                notifications[1].map(noti => {
                    counts.issues[noti.state] = noti.count;
                });
                notifications[2].map(noti => {
                    counts.issues[noti.state] = counts.issues[noti.state] + noti.count;
                });
                notifications[3].map(noti => {
                    counts.issues[noti.state] = counts.issues[noti.state] + noti.count;
                });
                if (notifications[4]) {
                    this.ticketsPinned = notifications[4];
                }
                const alarmCount = counts.alarms.acknowledged + counts.alarms.new;
                const issueCount = counts.issues.new + counts.alarms.acknowledged;

                this.issueCount = issueCount;
                this.alarmCount = alarmCount;

                if (lastReadAt === null) {
                    // Just logged in
                    const alarmNotificationCount = notifications[5].length;
                    if (alarmNotificationCount) {
                        const plural = `${alarmNotificationCount} alarms`;
                        const singular = `1 alarm`;
                        this.apiService.toastWarn(alarmNotificationCount === 1 ? singular : plural, '');
                    }
                } else {
                    // Logged in user for at leaset one set of notifications
                    const newNoti = notifications[5].length ? notifications[5]
                        .filter(noti => (lastNotification
                            .filter(last => last.id === noti.id).length === 0)) : [];

                    const resolvedNoti = lastNotification.length ? lastNotification
                        .filter(last => (notifications[5]
                            .filter(noti => last.id === noti.id).length === 0)) : [];

                    if (newNoti.length) {
                        this.apiService.toastWarn(`Alarm`, `${newNoti.length} new.`);
                        this.storeService.setDataChanged('alarm', 'changed', newNoti);
                    }
                    if (resolvedNoti.length) {
                        this.storeService.setDataChanged('alarm', 'changed', resolvedNoti);
                        this.apiService.toastSuccess(`Alarm`, `${resolvedNoti.length} resolved.`);
                    }
                }
                this.apiService.lastNotification = notifications[5];
                const bgCount = notifications[6][0]?.count || 0;
                if (bgCount === 0 && this.backgroundCount > 0) {
                    this.apiService.toastSuccess(`All Background jobs completed`, '');
                } else if (bgCount > 0 && this.backgroundCount > bgCount) {
                    this.apiService.toastSuccess(`Background job completed`, '');
                } else if (bgCount > 0 && this.backgroundCount < bgCount) {
                    this.apiService.toastSuccess(`Background job added`, '');
                }
                this.backgroundCount = bgCount;
            });
    }

    startTimers() {
        if (this.timerSubscription) {
            return;
        }
        const hasSeen = +(localStorage.getItem('seenversion') || 0);
        if (hasSeen >= 1020302) {
            // Only show the tooltip once.
            this.hasSeenNewFeature = true;
        } else {
            setTimeout(() => {
                this.hasSeenNewFeature = true;
                localStorage.setItem('seenversion', '1020302');
            }, 8000);
        }

        // Poll for the users notifications.
        const notificationTimer = timer(1500, 60000);

        this.timerSubscription = notificationTimer.subscribe(t => {
            console.log(' notification tick', t);
            this.getNotifications();
        });

    }

    isAuthorised(): boolean {
        return this.authorised;
    }

    goto(event, url) {
        this.router.navigate([url]);
        event.stopPropagation();
    }

    gotoExternalWebsite() {
        window.location.href = 'https://4dmonitoring.co.uk';
    }

    navigateTo(destination: string) {
        this.router.navigate([destination]);
    }

    updateHeader(evt) {
        const ets = evt.target.scrollTop || 0;
        const etc = evt.target.clientTop || 0;
        this.currentPosition = (window.pageYOffset || ets) - (etc);
        const isScrolled = (this.currentPosition >= this.changePosition);
        if (this.isScrolled !== isScrolled) {
            console.log(`Scrolling change ${isScrolled}`);
            this.isScrolled = isScrolled;
        }
    }

    ngAfterViewInit(): void {
        // Give the application access to the rootContainer
        this.storeService.setRootContainer(this.rootContainer);
    }

    /**
     * Page option selected from top right menu
     */
    clickPageOption(option: PageOption) {
        this.storeService.setPageOption(option);
    }

    contextMenu() {

    }

    themeToggle() {
        if (this.theme === 'dark') {
            this.storeService.setTheme('light');
        } else {
            this.storeService.setTheme('dark');
        }
    }
}
