import { Asset } from "./asset";
import { Building } from "./building";

export class BuildingReview {
	state: BuildingReviewStateType;
	title = 'New Review'
	review: { dates: { start: Date, end: Date }, rag: 'green' | 'amber' | 'red' };
	createdBy: { id: number, name: string };
	building: Building;
	collection: { id: number, assets: Asset[] };

	/**
	 * 
	 * @param data	An existing review already saved
	 * @returns 
	 */
	constructor(data?: any) {
		if (!data) {
			return;
		}

		const state = data.state ?? data.review?.state;
		const start = data.start ?? data.review?.reviewDate;
		const end = data.end ?? data.review?.reviewDateEnd;
		const rag = data.rag ?? data.review?.rag;

		if (!state) {
			// Use the createNewReview for new reviews
			return;
		}

		this.state = state;
		this.building = new Building(data.building);
		if (data.collection) {
			this.collection = data.collection;
		} else {
			this.collection = { id: data.config.cid, assets: data.assets.map(a => new Asset(a)) };
		}
		this.review = { dates: { start: new Date(start), end: new Date(end) }, rag };
		this.createdBy = data.user ?? null;
		this.title = `${this.review.dates.start.toLocaleDateString()} to ${this.review.dates.end.toLocaleDateString()}`;
	}

	createNewReview(building: Building, start: Date, end: Date, collection: { id: number, assets: Asset[] }) {
		this.building = building;
		this.collection = collection;
		this.review.dates = { start, end }
		this.createdBy = { id: null, name: null };
	}

	serialise() {
		return {
			title: this.title,
			review: this.review,
			building: this.building?.id,
			collectionId: this.collection?.id
		};
	}
}

export type BuildingReviewStateType = 'new' | 'init' | 'active' | 'done';
