import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CmsItemBlock, BuildCmsItemBlock } from '../../../classes/cms-item-block';
import { CMSItem } from '../../../classes/cms-item';
import { ThemeType } from 'app/shared/store.service';

@Component({
  selector: 'app-cms-item',
  templateUrl: './cms-item.component.html',
  styleUrls: ['./cms-item.component.css']
})
export class CmsItemComponent implements OnInit {

  @Input()
  showDate: boolean = true;

  @Input()
  theme: ThemeType = 'dark';

  @Input()
  isEditing: boolean = false;

  @Input()
  public set item(v: CMSItem) {
    this._item = v;
    console.log('SET ITEM', v);
  }

  public get item(): CMSItem {
    return this._item;
  }

  _item: CMSItem;

  @Output()
  onBlockClicked: EventEmitter<BuildCmsItemBlock> = new EventEmitter<BuildCmsItemBlock>();

  @Output()
  onBlockTrashed: EventEmitter<BuildCmsItemBlock> = new EventEmitter<BuildCmsItemBlock>();

  @Output()
  OnBlockMoved: EventEmitter<OnBlockMovedInterface> = new EventEmitter<OnBlockMovedInterface>();


  constructor() { }

  ngOnInit() {
  }

  blockClicked(block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockClicked.emit(block)`);
    this.onBlockClicked.emit(block);
  }

  blockTrashed(block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockTrashed.emit(block)`);
    this.onBlockTrashed.emit(block);
  }

  blockMoved(direction: string, block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockMoved.emit(block)`);
    this.OnBlockMoved.emit({ block, direction });
  }
}

export interface OnBlockMovedInterface {
  direction: string;
  block: BuildCmsItemBlock;
}
