@if(licenseCollection()) {

<app-theme-page-section label="Overview">
    @if(counts()){
    <app-billing-stats [counts]="counts()"></app-billing-stats>
    }@else {
    <div style="height:134px">
        <i class="mdi mdi-loading mdi-spin-x2"></i>
    </div>
    }
</app-theme-page-section>

<app-theme-page-section label="Licenses" [toolbar]="[
    {select: [{id:'',label:'All months'}, {id:'January', label:'January'}, {id:'February', label:'February'}, {id:'March', label:'March'},
    {id:'April', label:'April'}, {id:'May', label:'May'}, {id:'June', label:'June'}, {id:'July', label:'July'},
    {id:'August', label:'August'}, {id:'September', label:'September'}, {id:'October', label:'October'},
    {id:'November', label:'November'},{id:'December', label:'December'}],
     label:'Month'},
    {icon:'mdi-microsoft-excel',label:'Export to excel',tag:'EXPORT_EXCEL'}
    ]" (onToolbarClick)="toolbarClick($event)" (onToolbarSelectChange)="toolbarSelectChange($event)">
    <div class="search flex">
        <input class="form-control" placeholder="search" [(ngModel)]="searchText" (ngModelChange)="search()">
        <app-button icon="mdi mdi-rotate-3d-variant" size="medium" (click)="licenseMustHaveValueClick()" [label]="licenseMustHaveValue() ? 'Has a value' : 'All&nbsp;Values'" tooltipPosition="left" pTooltip="Toggle view to show only licenses with a value or all licenses"></app-button>
    </div>
    <table class="table" [hidden]="selectedLicense" aria-label="Licenses">
        <thead>
            <tr>
                <th class="can-sort" (click)="sort('title')">
                    <span>
                        For
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'title' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'title' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th class="can-sort" (click)="sort('billingClientTitle')">
                    <span pTooltip="Billing client">
                        Client
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'billingClientTitle' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'billingClientTitle' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th class="can-sort" (click)="sort('org')">
                    <span>
                        Org
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'org' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'org' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th class="can-sort" (click)="sort('value')">
                    <span>
                        Value
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'value' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'value' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th class="can-sort" (click)="sort('commission')">
                    <span>
                        Commission
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'commission' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'commission' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th class="can-sort text-right" (click)="sort('expiresAt')">
                    <span>
                        Expires
                        <i class="fa" [ngClass]="{'fa-angle-down':sortBag.column === 'expiresAt' && sortBag.order==='asc', 'fa-angle-up':sortBag.column === 'expiresAt' && sortBag.order==='desc'}"></i>
                    </span>
                </th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            @for(item of filteredLicenses();track item.id) {
            <tr [class.has-expired]="item.hasExpired">
                <td (click)="licenseClick(item)" class="can-click">
                    @if(item.building) {
                    <span pTooltip="Building #{{item.building.id}}"><i class="mdi mdi-office-building-outline"></i> {{item.building.title}}
                        @if(item.notes) {
                        <i class="mdi mdi-note-text-outline" pTooltip="Notes are available"></i>
                        }
                        @if(!item.isActive) {
                        <i class="mdi mdi-triangle-outline" pTooltip="DISABLED, not billed"></i>
                        }
                    </span>
                    }
                    <span *ngIf="item.gateway" pTooltip="Gateway #{{item.gateway.id}}"><i class="mdi mdi-router-wireless"></i> {{item.gateway.title}}</span>
                </td>

                <td (click)="licenseClick(item)" class="can-click">
                    <span *ngIf="item.billingClient">{{item.billingClient.shortTitle}}</span>
                </td>
                <td (click)="licenseClick(item)" class="can-click">
                    <span *ngIf="item.building">{{item.building.site.org.shortTitle}}</span>
                </td>
                <td (click)="licenseClick(item)" class="can-click">{{item.value}}</td>
                <td (click)="licenseClick(item)" class="can-click">{{item.commission}}</td>
                <td (click)="licenseClick(item)" class="can-click text-right">{{item.expiresAt |
                    amDateFormat:'DD MMM YYYY'}}</td>
                <td class="text-right">
                    <p-button pTooltip="Move expiry forward to a valid date" tooltipPosition="left" *ngIf="item.hasExpired" label="Disable" icon="mdi mdi-calendar-arrow-right" styleClass="p-button-xsm p-button-secondary" (click)="advanceExpiry(item);$event.stopPropagation()" label="Advance Expiry"></p-button>


                </td>
            </tr>
            }
        </tbody>
    </table>
</app-theme-page-section>


} @else {
<app-please-wait></app-please-wait>
}

@if(licenseSelected()) {
<app-dialog [canScroll]="true" (onClose)="licenseSelected.set(null)" [header]="licenseSelected().title">
    <app-building-license-home [license]="licenseSelected()" (onSave)="licenseUpdated()"> </app-building-license-home>
</app-dialog>

}

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
