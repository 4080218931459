import { Component, ElementRef, OnInit, ViewChild, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { APIService, IGetOrgTelemetryResponse } from 'app/shared/api.service';
import { SiteService } from 'app/shared/site.service';
import { WindowService } from 'app/shared/window.service';
import { MenuItem } from 'primeng/api';
import { IRagCounts } from './telemetry-list-legend/telemetry-list-legend.component';

@Component({
  selector: 'app-telemetry-list',
  templateUrl: './telemetry-list.component.html',
  styleUrls: ['./telemetry-list.component.css']
})
export class TelemetryListComponent implements OnInit {
  sites = signal<Site[]>(null);
  filterCount = signal<number>(null);
  defaultSiteId: number;
  defaultSite: Site = null;
  selectedAsset: any;
  results: any[];
  isMobile: boolean;
  breadcrumb: MenuItem[] = [];
  isLoadingSiteOrAsset: boolean;
  isLoadingSites: boolean;
  isLoadingAssetsForSite: boolean;

  filterResults: any[];
  searchFor: string;
  gatewaysHidden = [];
  gatewaysHiddenAsObject = {};

  @ViewChild('top', { static: true }) contentPage: ElementRef;

  ragCounts = signal<IRagCounts>(null);

  constructor(private siteService: SiteService, private apiService: APIService, private windowService: WindowService) {

  }

  ngOnInit(): void {
    this.isMobile = this.windowService.isMobile();
    this.isLoadingSiteOrAsset = true;
    this.isLoadingSites = true;
    this.loadGatewaysHidden();
    this.siteService.getSites().then(sites => {
      this.sites.set(sites.filter(s => s.gatewayCount));
      this.isLoadingSites = false;
      if (!this.isMobile) {

        this.defaultSite = this.loadDefualtSite();
        if (!this.defaultSite) {
          this.defaultSite = sites[0];
        }
        this.loadTelemetryForDefaultSite();
      }
      this.isLoadingSiteOrAsset = false;
    });
  }

  gatewayHeaderClick(index: number) {

    const gatewayId = this.filterResults[index][0].gatewayId;

    const isHidden = this.gatewaysHidden.some(id => id === gatewayId);

    if (isHidden) {
      this.gatewaysHidden = this.gatewaysHidden.filter(id => id !== gatewayId);
    } else {
      this.gatewaysHidden.push(gatewayId);
    }
    this.gatewaysHiddenBuildHelpers();
    this.saveGatewaysHidden();
  }

  gatewaysHiddenBuildHelpers() {
    this.gatewaysHiddenAsObject = {};
    this.gatewaysHidden.forEach(id => {
      this.gatewaysHiddenAsObject[id] = true;
    });
  }

  searchChanged(searchText) {
    this.searchFor = searchText.toLowerCase();
    this.filter();
  }

  ragSelected(rag) {
    if (this.searchFor === rag) {
      this.searchFor = '';
    } else {
      this.searchFor = rag;
    }
    this.filter();
  }

  filter() {

    const ragCounts: IRagCounts = { red: 0, amber: 0, green: 0, none: 0 };

    this.results.forEach(g => g.forEach((a: IGetOrgTelemetryResponse) => {
      ragCounts[a.rag || 'none']++;
    }));

    this.ragCounts.set(ragCounts);

    if (!this.searchFor || this.searchFor.length < 1) {
      this.filterResults = this.results;
      this.filterCount.set(this.results.length);
      return;
    }

    this.filterResults = [];;

    for (let index = 0; index < this.results.length; index++) {
      const gateway = this.results[index];

      const exists = gateway.filter((asset: IGetOrgTelemetryResponse) => {

        if (asset.title.toLowerCase().includes(this.searchFor)) {
          return true;
        }
        if (String(asset.assetId) === this.searchFor) {
          return true;
        }

        if (asset.gatewayTitle.toLowerCase().includes(this.searchFor)) {
          return true;
        }

        if (asset.rag && asset.rag.includes(this.searchFor)) {
          return true;
        }

        if (!asset.rag && this.searchFor === 'none') {
          return true;
        }

        if (asset.gatewayId === this.searchFor) {
          return true;
        }

        return false;
      });
      if (exists.length) {
        this.filterResults.push(exists);
      }
    }

    //console.log('SENDING NEW filter count', this.filterResults.length);
    this.filterCount.set(this.filterResults.length);


    this.ragCounts.set(ragCounts);

  }

  saveDefaultSite() {
    const payload = this.defaultSite.serialise();
    localStorage.setItem(this.apiService.getUserOrg().id + ':telemetry:site', JSON.stringify(payload));
  }

  saveGatewaysHidden() {
    localStorage.setItem(this.apiService.getUserOrg().id + ':telemetry:gateways', JSON.stringify({ hidden: this.gatewaysHidden }));
  }

  loadDefualtSite(): Site | null {
    const data = localStorage.getItem(this.apiService.getUserOrg().id + ':telemetry:site');
    if (data) {
      const site = new Site(JSON.parse(data));

      return site;
    }

    return null;
  }

  loadGatewaysHidden() {
    try {
      const data = localStorage.getItem(this.apiService.getUserOrg().id + ':telemetry:gateways');
      if (data) {
        this.gatewaysHidden = JSON.parse(data).hidden;
        this.gatewaysHiddenBuildHelpers();
      }
    } catch (e) {
      console.log(e);
    }
  }

  assetLoaded(event: any) {
    this.isLoadingSiteOrAsset = false;
  }

  backToSites() {
    this.defaultSite = null;
    this.selectedAsset = null;
    this.results = null;
    this.isLoadingSiteOrAsset = null;
  }

  siteClick(site: Site) {
    this.defaultSite = site;
    this.selectedAsset = null;
    this.isLoadingSiteOrAsset = null;
    this.saveDefaultSite();
    this.loadTelemetryForDefaultSite();

    this.contentPage.nativeElement.scrollTo(0, 0);
  }

  crumbClick(index?: number) {

    if ((index === undefined || this.breadcrumb?.length === 1) && this.isMobile) {
      this.backToSites();
    }

    switch (index) {
      case 0:
        this.closeAsset();
        break;
    }
  }

  loadTelemetryForDefaultSite() {
    if (!this.defaultSite) {
      return;
    }
    this.isLoadingSiteOrAsset = true;
    this.isLoadingAssetsForSite = true;
    this.breadcrumb = [{ label: this.defaultSite.title }];

    this.apiService.getOrgTelemetry({ site: { id: this.defaultSite.id } })
      .then(result => {
        let gateways = [];
        if (result.length === 0) {
          this.results = [];
          this.isLoadingSiteOrAsset = this.isLoadingAssetsForSite = false;

          return;
        }
        result = result.filter(asset => asset.assetType !== 32 && asset.assetType !== 21 && asset.assetType !== 31 && asset.assetType !== 12);
        // Allocate gateways their own array location
        for (let idx = 0; idx < result.length; idx++) {
          let gatewayId = result[idx].gatewayId;
          if (idx === 0 || result[idx - 1].gatewayId !== gatewayId) {
            gateways.push([]);
          }
          gateways[gateways.length - 1].push(result[idx]);
        }

        this.results = gateways.reverse();
        this.isLoadingSiteOrAsset = this.isLoadingAssetsForSite = false;
        this.filter();
      });
  }

  showTelemetry(asset: IGetOrgTelemetryResponse) {
    this.isLoadingSiteOrAsset = true;
    this.selectedAsset = asset;
    this.breadcrumb.push({ label: asset.title });
    try {
      if (!asset.assetId) {
        console.error('NO ASSET / ID');
        return;
      }
      this.apiService.postRoute(`/telemetry/${asset.assetId}`);
    } catch (e) {
      this.isLoadingSiteOrAsset = false;
      console.log(e);
    }
  }

  closeAsset() {
    this.selectedAsset = null;
    this.isLoadingSiteOrAsset = null;
    this.breadcrumb.pop();
  }
}
