import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, signal } from '@angular/core';
import { APIService } from '../shared/api.service';
import { StoreService, ThemeType } from '../shared/store.service';
import { User } from '../classes/user';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { CMSItem } from '../classes/cms-item';
import { NetworkService } from 'app/shared/network.service';
import { PlatformModuleService } from 'app/shared/platform-module.service';
import { AssetService } from 'app/shared/asset.service';
import { WindowService } from 'app/shared/window.service';

declare var window: any;

@Component({
	selector: 'app-login',
	styleUrls: ['./login.component.css'],
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

	@ViewChild('title', { static: true })
	titleElement: ElementRef;

	@ViewChild('email', { static: true })
	emailElement: ElementRef;

	_inputFocus = signal<boolean>(false);

	flashMessage: boolean;
	messageColour: string = 'red';
	cmsItems: CMSItem[];
	user: User = new User();
	tokenId: string;
	newPassword: string;
	state: number;
	timerSubscription: Subscription;
	timerMaximum = 40;
	ingested: string;
	onInput: boolean;
	// Attempts at logging in
	attempts = 0;
	internetDown: boolean;
	siteDown = false;
	domain = '4dml';
	theme = signal<ThemeType>('light');
	isMobile = signal<boolean>(false);

	appVersion: string;
	message = '';
	isProcessingForgotPassword: boolean;

	@HostListener('document:keydown.enter', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		console.log(event.key);
		if (event.key === 'Enter') {
			if (this.user.email && this.user.password) {
				this.login();
			}
		}
	};
	constructor(private platformModuleService: PlatformModuleService, private router: Router, private apiService: APIService, private route: ActivatedRoute, private storeService: StoreService, private networkService: NetworkService, private assetService: AssetService, windowService: WindowService) {
		console.log('login constructor');

		const subdomain = window.location.hostname.split('.')[0];

		if (subdomain !== 'portal' && subdomain !== 'public') {

			this.domain = subdomain;
			if (subdomain === "10" || subdomain === "192") { this.domain = 'developer'; }
		}

		storeService.theme.subscribe(theme => this.theme.set(theme));

		storeService.setIsLoading(true);
		this.user = new User();
		this.apiService.clearSession();

		this.route.params.subscribe((params: Params) => {
			this.tokenId = params['tokenid'];
			console.log(this.tokenId);
		});

		this.apiService.clearUser();
		this.storeService.clearStorage();
		this.assetService.clearUserFavourites();
		try {
			let user: any = localStorage.getItem('user');
			let session: any = localStorage.getItem('session')
			if (user) {
				user = JSON.parse(user);
				this.user.email = user.email;
				this.user.role = user.role;
				if (session) {
					session = JSON.parse(session);
					try {
						const timestamp = new Date(session.timestamp);
						if ((+new Date() - (+timestamp)) < 24 * 60 * 60) {
							// Only use temp password if valid
							this.user.password = session.passwordToken;
						}
					} catch (e) {
						console.log(e);
					}
				}
			}
		} catch (e) {
			try {
				// May be a corruption in the local storage -attempt to remove them
				console.log('ERROR_REMOVE_USER_SESSION');
				localStorage.removeItem('user');
				localStorage.removeItem('session');
			} catch (e) { }
		}
		this.state = 0;

		apiService.getCms().then(result => {
			console.log(result);
			this.cmsItems = result;
		});
		this.appVersion = apiService.appVersion;
		this.isMobile.set(windowService.isMobile());
	}

	inputFocus() {
		this._inputFocus.set(true);
	}

	setMessage(msg: string, colour: string = 'red') {
		this.flashMessage = true;
		this.messageColour = colour;
		setTimeout(() => {
			this.flashMessage = false;
			this.message = msg;
		}, 100);
	}

	resetPassword() {
		this.user.password.trim();
		this.newPassword.trim();
		// Fail if passwords do not match
		if (this.user.password !== this.newPassword) {
			this.setMessage('passwords do not match');
			return false;
		}

		this.apiService.resetPassword(this.getEmail(), this.tokenId, this.user.password).then(
			changed => {
				if (changed) {
					console.log('Password changed');
					this.tokenId = null;
					this.setMessage('Password was changed, please login.', 'green');
				} else {
					this.setMessage('Password reset failed, you may have used an old email.');
				}

				return false;
			});
	}

	/**
	 * Get the email, remove spaces and make lowercase
	 */
	getEmail() {
		return this.user.email.trim().toLowerCase();
	}

	login(): void {
		if (this.state !== 0) {
			// Logging in
			return;
		}

		if (this.user.email.length < 2) {
			this.emailElement.nativeElement.focus();
			return;
		}

		if (this.user.password.length < 3) {
			console.log('need a password of at least 3 characters');
			return;
		}

		if (this.tokenId) {
			this.resetPassword();
			return;
		}

		this.state = 1;
		this.apiService
			.login(this.getEmail(), this.user.password)
			.then(user => {
				this.state = 0;
				if (user) {
					this.storeService.setIsLoading(true);
					if (user.isRestricted) {
						this.storeService.setIsLoading(false);
						const [module] = user.modules.values();
						const url = [PlatformModuleService.modules[module].route];
						this.router.navigate(url);
					} else {
						this.router.navigate(['/dashboard']);
					}
				} else {
					// Failed login, clear session as it is now invalid
					// Clear the temporary token
					console.log('FAILED_LOGIN_REMOVE_SESSION');
					localStorage.removeItem('session');
					// Increment the attempts at logging in
					this.attempts += 1;
					// Remove the password for another attempt
					this.user.password = '';
					this.setMessage('We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.');
				}
			});
	}

	forgotPassword() {
		if (this.isProcessingForgotPassword) {
			return;
		}
		if (this.getEmail().length < 10) {
			this.apiService.toastWarn('Please enter an email address, and try again', '');
			return;
		}
		this.isProcessingForgotPassword = true;
		this.apiService.forgotPassword(this.getEmail()).then(response => {
			this.setMessage(response, 'green');
			this.isProcessingForgotPassword = false;
		});
	}

	ngOnInit(): void {
		const lost = this.route.snapshot.queryParams['lost'];
		console.log('queryparams', this.route.snapshot.queryParams);
		console.log(lost);

		this.route.params.subscribe((params: Params) => {
			this.tokenId = params['tokenid'];
		});
		this.storeService.setIsLoading(false);
	}

	grabStatus() {
		this.apiService
			.getStatus()
			.then(status => {
				console.log(status);
				this.ingested = status.ingested
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				this.siteDown = false;
			})
			.catch(error => {
				console.log(error);
				this.siteDown = true;
				this.internetDown = !this.networkService.hostReachable();
			});
	}

	// Handle input focus
	focus(control: string, event: any) {
		this.onInput = true;
		setTimeout(() => window.scrollTo(0, 0));
	}

	ngOnDestroy() {
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
	}
}
