@defer(when sitePlanComplete) {
<article>
	<main>
		<div class="movement-controls isMobile">
			<!-- set the container for the content-->
			<div>
				<!-- divide the content into left and right -->
				<div>
					<span class="mdi mdi-chevron-left" (click)="navigateBack()"></span>
				</div>
				<div>
					<span class="mdi mdi-magnify-minus-outline" (click)="zoom('out')"></span>
					<span class="mdi mdi-fullscreen" (click)="zoom('reset')"></span>
					<span class="mdi mdi-magnify-plus-outline" (click)="zoom('in')"></span>
					<span class="mdi mdi-arrow-left-thin-circle-outline" (click)="panDirection('left')"></span>
					<span class="mdi mdi-arrow-right-thin-circle-outline" (click)="panDirection('right')"></span>
					<span class="mdi mdi-arrow-up-thin-circle-outline" (click)="panDirection('up')"></span>
					<span class="mdi mdi-arrow-down-thin-circle-outline" (click)="panDirection('down')"></span>
				</div>

			</div>
		</div>
		<div class="svg-parent" #svgparent>
			<img #svgimage [class.bw]="!sitePlanComplete.sitePlan?.isColour" src="https://55auilnnlh.execute-api.eu-west-2.amazonaws.com/4d/floorplans/{{sitePlanComplete.sitePlan.planKey}}.svg#svgView(viewBox({{sitePlanComplete.viewbox.calculated.x}},{{sitePlanComplete.viewbox.calculated.y}},{{sitePlanComplete.viewbox.calculated.w}},{{sitePlanComplete.viewbox.calculated.h}}))" (load)="svgLoadedFromInternet()" [ngStyle]="{filter: sitePlanComplete.sitePlan.filter}">

			@if(isSVGImageRendered && assets.length && (view === 'realtime' || (view === 'occupancy daily' && assetsHourlySummary))) {
			<svg class="svg-data" #svgDataContainer [attr.viewBox]="sitePlanComplete.viewbox.calculated.x + ' ' + sitePlanComplete.viewbox.calculated.y + ' ' + sitePlanComplete.viewbox.calculated.w + ' ' + sitePlanComplete.viewbox.calculated.h">
				<filter x="0" y="0" width="1" height="1" id="label-background">
					<feFlood flood-color="green"></feFlood>
					<feComposite in="SourceGraphic"></feComposite>
				</filter>
				<filter id="blurme">
					<feGaussianBlur stdDeviation="5"></feGaussianBlur>
				</filter>
				@for(area of sitePlanComplete.areas; track area) {
				<g>
					@if(areaFocus?.id === area.id) {
					<rect class="focused-area" [attr.width]="area.width" [attr.height]="area.height" [attr.x]="area.x" [attr.y]="area.y"></rect>
					}
				</g>
				}

				@switch(view) {

				@case('occupancy daily') {
				@for(asset of _assets; track asset.id;let co = $count) {
				<g class="asset " [attr.transform]="asset.rotateDeg ? 'rotate(' + asset.rotateDeg + ')' :''">

					@if(asset.svg.x !== null && asset.assetType_id == 50 && asset.customIcon === 'iot-desk') {
					<g (click)="clickSelectAsset(asset)">

						<!--- ICON ASSET-->

						@if(asset.svgIcon) {
						<g class="icon-asset" [class.no-value]="!asset.showValue" [class.change-up]="asset.changeDiff > 0" [class.change-down]="asset.changeDiff < 0">
							<rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight  + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" class="hover-target" />

							<rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" style="pointer-events: none;" />

							<g class="images">
								<svg:image class="icon filter-rating-{{assetsHourlySummary?.assets[asset.id]?.daysOfHours[daysOfHour].rating}}" [attr.href]="asset.svgIconPath" [attr.height]="asset.svgIconDimensions.h" [attr.width]="asset.svgIconDimensions.w" [attr.x]="asset.svg.x + asset.svgIconDimensions.x" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" />
							</g>
						</g>
						}
					</g>
					}
				</g>
				}
				}

				@case('realtime') {
				<!-- SHAPES -->
				@for(shape of sitePlanComplete.shapes; track shape.id;let i = $index; let co = $count) {
				<g class="shape" (click)="shapeClick(shape)">
					<g class="shape-container" [innerHTML]="sanitizedHTML(shape, i, co)" [class.rag-red]="shape.atRAG==='red'" [class.rag-amber]="shape.atRAG==='amber'" [class.selected]="shape.id === selectedShape?.id">
					</g>
				</g>
				}
				@for(asset of _assets; track asset.id) {
				<g class="asset {{asset.rag}}" [attr.transform]="asset.rotateDeg ? 'rotate(' + asset.rotateDeg + ')' :''">

					<text>{{assetsHourlySummary && assetsHourlySummary.assets}}</text>
					@if(asset.svg.x !== null && ((sitePlanComplete.sitePlan.type === 'basic' && (asset.isAlwaysVisible || !asset.shapeId || asset.shapeId === selectedShape?.id)) || (sitePlanComplete.sitePlan.type === 'shape' && selectedArea())) || (asset.shapeId && (asset.rag === 'amber' || asset.rag === 'red'))) {
					<g [ngClass]="{'rag-green':asset.rag==='green',
				                                'rag-orange':asset.rag==='orange' || asset.rag==='amber',
				                                'rag-red':asset.rag==='red',
				                                'rag-none': !asset.rag,
				                                'disconnected': asset.daysOld > 30}" [style.font-size]="(sitePlanComplete.sitePlan.fontSize ? sitePlanComplete.sitePlan.fontSize : 14) + 'px'" (click)="clickSelectAsset(asset)">

						@if(asset.type==='circle' && !asset.svgIcon) {
						<circle class="asset-circle" [attr.cx]="asset.svg.x" [attr.cy]="asset.svg.y" [attr.r]="scales.r" [attr.fill]="asset.rag || (asset.purpose.id === 6 && (asset.value === 'occupied' || asset.value === 'In Use') ? 'red' : 'green') || '#f0f0f0'">
							<title>{{asset.id}} - {{asset.title}} - {{asset.identifier}} --
								{{asset.daysOld > 0 ? 'Updated ' + asset.daysOld + ' days ago.' :
								asset.updatedAt |
								date:'dd-MM-YY HH:mm'}}
							</title>
						</circle>
						}


						@if(asset.svgIcon) {
						<g class="icon-asset" [class.no-value]="!asset.showValue" [class.change-up]="asset.changeDiff > 0" [class.change-down]="asset.changeDiff < 0">
							<rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight  + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" class="hover-target" />

							<rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + asset.svgPrecision?.rect.y" [attr.width]="asset.svgWidth" [attr.height]="asset.svgHeight + asset.svgPrecision?.rect.h" [attr.fill]="asset.background.colour" [attr.stroke]="asset.border.colour" rx="3" style="pointer-events: none;" />

							<g class="images">

								<svg:image class="change-direction up" [attr.x]="asset.svg.x + asset.svgIconDimensions.x + 1" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" href="assets/svg/black/up-arrow.svg" [attr.height]="asset.svgIconDimensions.h /2" [attr.width]="asset.svgIconDimensions.w / 2" />

								<svg:image class="change-direction down" [attr.x]="asset.svg.x + asset.svgIconDimensions.x + 1" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" href="assets/svg/black/down-arrow.svg" [attr.height]="asset.svgIconDimensions.h /2" [attr.width]="asset.svgIconDimensions.w / 2" />

								<svg:image class="icon" [attr.href]="asset.svgIconPath" [attr.height]="asset.svgIconDimensions.h" [attr.width]="asset.svgIconDimensions.w" [attr.x]="asset.svg.x + asset.svgIconDimensions.x" [attr.y]="asset.svg.y + asset.svgIconDimensions.y + 1" />
							</g>

							@if(asset.showValue) {
							<text [attr.x]="(asset.svg.x + asset.svgTextAdjust.x) + asset.svgPrecision?.text.x" [attr.y]="(asset.svg.y + asset.svgTextAdjust.y) + asset.svgPrecision?.text.y" font-family="Roboto" [style.font-size]="asset.mfs" class="value">{{asset.value}}</text>
							}
						</g>
						}


						@if(asset.type==='panel') {
						<g>
							<rect [attr.x]="asset.svg.x" [attr.y]="asset.svg.y" [attr.width]="asset.mw || 10" [attr.height]="asset.mh || (asset.mfs * 2) || ( sitePlanComplete.sitePlan.fontSize * 1.2)" [attr.fill]="asset.rag || (asset.purpose.id === 6 && (asset.value === 'occupied' || asset.value === 'In Use') ? 'red' : 'green') || '#f0f0f0'">
								<title>{{asset.id}} - {{asset.title}} - {{asset.identifier}}
								</title>
							</rect>
							<text title="{{asset.id}} - {{asset.title}} - {{asset.identifier}}" id="asset-{{asset.id}}" class="asset-panel title" [attr.x]="asset.svg.x + ((asset.mw || 10) / 2)" [attr.y]="asset.svg.y + asset.my" text-anchor="middle" [style.font-size]="asset.mfs">
								{{asset.svg.label}}
							</text>
							<text title="{{asset.id}} - {{asset.title}} - {{asset.identifier}}" id="asset-{{asset.id}}" class="asset-panel value" [attr.x]="asset.svg.x + ((asset.mw || 10) / 2)" [attr.y]="asset.svg.y + asset.my + asset.my " text-anchor="middle" [style.font-size]="asset.mfs">
								{{asset.value || (asset.purpose.id === 6 ? (asset.rag === 'red' ? 'In Use' :
								'Free')
								: 'Free') }}
							</text>
						</g>
						}
						@if(asset.type === 'circle' && ! asset.svgIcon) {
						<g>
							<text [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + 1 " text-anchor="middle">
								@if(asset.assetType_id === 17) {
								<tspan>
									{{asset.assetType_id === 17 ? (asset.value === '0' || asset.value ===
									'0.0'
									?
									'Closed' : 'Open') : ''}}
								</tspan>
								}
								@if(asset.purpose.id === 6) {
								<text> {{asset.svg.label}}</text>
								} @else {
								<tspan>
									{{asset.assetType_id !== 17 ?
									(asset.assetType_id !== 20 ? asset.value : (+asset.value).toFixed(0)) :
									''}}
								</tspan>
								}

							</text>
						</g>
						}
						@if(asset.type !== 'panel' && asset.assetType_id !== 17 && !asset.svgIcon) {
						<g>
							<!-- default is to have a label -->
							<text class="label" [attr.x]="asset.svg.x" [attr.y]="asset.svg.y + scales.labelYOffset" text-anchor="middle">
								{{asset.purpose.id !== 6 ? asset.svg.label : ''}}
							</text>
						</g>
						}
					</g>
					}
				</g>
				}
				}
				}
			</svg>
			}

			<div [class.hidden]="!hoverSensor()">
				<div class="info-box" #info>
					@if(hoverSensor()) {
					<div>
						<div class="title">{{hoverSensor().asset.title}}</div>
						<div class="type">{{hoverSensor().asset.assetTypeTitle}}</div>
						<div class="id">
							#{{hoverSensor().asset.id}} - {{hoverSensor().asset.identifier}}
						</div>
						<div>{{hoverSensor().asset.updatedAt |
							amDateFormat:'DD/MM/YY HH:mm' }}, <i>{{hoverSensor().asset.updatedAt |
								amTimeAgo}}</i>
						</div>
						@if(hoverSensor().asset.assetType_id === 50 || ( hoverSensorTelemetry()?.telemetry && hoverSensor().asset.assetType_id !== 1)) {
						<div class="asset-chart">
							<div>Today</div>
							<div>
								@if(hoverSensor().asset.assetType_id !== 50) {
								<span>{{hoverSensorTelemetry().max}}</span>
								}
							</div>
							<div>
								@if(hoverSensor().asset.assetType_id !== 50) {
								<span>{{hoverSensorTelemetry().min}}</span>
								}
							</div>
							<div class="d3-chart">
								@switch(hoverSensor().asset.assetType_id) {
								@case(50) {
								<div class="hover-occupancy-panel">
									<app-setpoint-query-occ-squares [assetId]="hoverSensor().asset.id"></app-setpoint-query-occ-squares>
								</div>
								}
								@default {
								<!-- chart only when not a button-->
								<app-d3-chart width="307" height="100" [asset]="hoverSensor().asset" [telemetry]="hoverSensorTelemetry().telemetry" [showLegend]="false" [minMaxFromData]="true">
								</app-d3-chart>
								}
								}
							</div>
						</div>
						}
						@if(hoverSensor().asset.assetType_id === 1) {
						<div class="asset-button">
							@for(row of hoverSensorTelemetry().telemetry?.reverse();track row;let i = $index) {
							<div>
								@if(i < 20) { <div>
									Touched {{row.d | amTimeAgo}}
							</div>
							}
						</div>
						}
					</div>
					}
				</div>
				}
			</div>
		</div>
		@if((selectedArea())) {
		<div class="current-area">
			<span (click)="clickFloorplanLabel()">{{selectedArea().title}}
				<i class="fa fa-fw fa-times"></i></span>
		</div>
		}

		@if(hoverObject()) {
		<div class="hover-panel" [style.left.px]="hoverObject().left" [style.top.px]="hoverObject().top">
			<div>{{hoverObject().obj.title}}</div>
			<div>{{hoverObject().left}}</div>
			<div>{{hoverObject().top}}</div>
		</div>
		}

		@if(isLoading) {
		<span class="is-loading">
			<i class="fa fa-fw fa-circle fa-spin"></i>
		</span>
		}
		</div>

		@if(viewAsset) {
		<div>
			<div class="modal__overlay">
				<app-site-plan-asset [asset]="viewAsset" (onBack)="viewAsset=null" [fullscreen]="true">
				</app-site-plan-asset>
			</div>
		</div>
		}

		@if(selectedShape) {
		<div>
			<div class="modal__overlay">
				<app-site-plan-shape [shape]="selectedShape" (onBack)="selectedShape=null" [fullscreen]="true">
				</app-site-plan-shape>
			</div>
		</div>
		}
	</main>

	@if(sitePlanComplete.sitePlan?.hasOccupancy && isSVGImageRendered) {
	<footer>
		<app-rating-ae></app-rating-ae>
	</footer>
	}
</article>
} @placeholder ( minimum 800ms) {
Please wait...
}

@if(planMessage()) {
<div class="plan-message">{{planMessage()}}</div>
}
