<div class="feature theme-{{theme}}" *ngIf="item">
  <div class="title">
    <span>{{item.title}}</span>
  </div>
  <div class="blocks" *ngFor="let block of item.blocks">
    <app-cms-item-block [isEditing]="isEditing" [block]="block" (onBlockClicked)="blockClicked(block)"
      (OnBlockTrashed)="blockTrashed(block)" (OnBlockMoved)="blockMoved($event, block)"></app-cms-item-block>
  </div>

  <div class="date" *ngIf="showDate">{{item.createdAt | amDateFormat:'MMMM YYYY'}}</div>

</div>
<div *ngIf="!item"><i class="fa fa-refresh fa-spin"></i></div>
