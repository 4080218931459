import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { StoreService, ThemeType } from 'app/shared/store.service';



@Component({
  selector: 'app-dashboard-telemetry-asset',
  templateUrl: './dashboard-telemetry-asset.component.html',
  styleUrls: ['./dashboard-telemetry-asset.component.css']
})
export class DashboardTelemetryAssetComponent implements OnInit {

  asset = input<Asset | undefined>(undefined);

  @Output()
  onAssetClick: EventEmitter<any> = new EventEmitter();

  theme:ThemeType;

  constructor(private storeService: StoreService) {
   this.theme = storeService.getTheme(true);
  }

  ngOnInit(): void {

  }

  assetClick() {
    this.onAssetClick.emit(true);
  }

  displayValue(asset) {
    return Asset.formattedValue(asset.value, asset.assetType, asset.purposeId, asset.updatedAt, asset.identifier);
  }

}
