import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-insights-landing',
  templateUrl: './insights-landing.component.html',
  styleUrls: ['./insights-landing.component.css']
})
export class InsightsLandingComponent implements OnInit {

  can: any = { review: false, admin: false, footfall: false };

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    this.can.review = this.can.admin = this.apiService.isAdmin();
    this.can.footfall = this.can.admin || this.apiService.moduleAccess["insights_footfall"];
  }

}
