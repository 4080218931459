<article class="fourd">
  @defer (when review()) {
    <section class="no-print toolbar">
      @if (isLoadingData) {
        <div class="loading">
          Loading
          @for (a of assets(); track $index) {
            @if ($index < assetsLoaded()) {
              <i class="mdi mdi-square" [class.green]="a.state === 'hasdata'" [class.red]="a.state === 'nodata'"></i>
            } @else {
              <i class="mdi mdi-square gray"></i>
            }
          }
        </div>
      } @else {
        <app-toolbar>
          <div class="left">{{ review()?.review.dates.start | date: 'dd MMM yyyy' }} - {{ review()?.review.dates.end | date: 'dd MMM yyyy' }}</div>
          <div class="right">
            <!-- <app-button
              (click)="printPage()"
              label="Print Report"
              icon="mdi mdi-printer"
              tooltipPosition="left"
              pTooltip="No need to upload, save and click complete." /> -->
            <!-- <app-button (click)="toggleBlocks()" label="Overlay hours" icon="mdi mdi-rotate-3d-variant" tooltipPosition="left" /> -->
            @if (review()?.state !== 'done') {
              <app-button
                label="Delete"
                icon="mdi mdi-trash-can"
                (click)="deleteReview()"
                tooltipPosition="top"
                pTooltip="This review can be deleted as the pdf hasn't been uploaded." />
            } @else {
              <app-button
                label="Delete"
                icon="mdi mdi-alert-outline"
                [isDisabled]="true"
                tooltipPosition="top"
                pTooltip="This review cannot be deleted as the pdf HAS been uploaded." />
              <app-button
                label="Change RAG"
                (click)="changeRAG()"
                icon="mdi mdi-pen"
                pTooltip="Change the RAG of the review from {{ review().review.rag ?? 'none' }}" />
            }
            <app-button
              class="ml-1"
              [label]="outstandingAnnotations ? 'Save progress' : 'Save & Complete'"
              icon="mdi mdi-check"
              (click)="saveClick()"
              [isCTA]="!outstandingAnnotations && !isPrinting" />
          </div>
        </app-toolbar>
      }
    </section>

    <section class="page-1">
      <div class="print-only">
        <app-insights-as-page1 [building]="review().building" [start]="review().review.dates.start" [end]="review().review.dates.end" />
      </div>
    </section>

    <section [class.is-dragging]="isDragging" (drop)="onDropHandler($event)">
      <main class="drag-message">
        DROP DOCUMENT HERE TO UPLOAD
        <p><app-button icon="mdi mdi-close" (click)="isDragging = false" label="Cancel" /></p>
      </main>

      @for (assetContainer of assets(); track assetContainer.asset.id) {
        <div class="asset asset-{{ assetContainer.asset.id }} mb-1">
          <div class="asset-title"><app-asset-icon [assetTypeId]="assetContainer.asset.assetType_id"></app-asset-icon> {{ assetContainer.asset.title }}</div>
          @if (assetContainer.telemetry?.length) {
            <app-d3-chart
              [asset]="assetContainer.asset"
              [hasToClickToInteract]="true"
              [canAnnotate]="true"
              [canZoom]="false"
              height="200"
              [telemetry]="assetContainer.telemetry"
              [showLegend]="true"
              [blocks]="blocks()"
              [minMaxFromData]="true"
              [penWidth]="0.8"
              [dateFrom]="review()?.review.dates.start"
              [dateTo]="review()?.review.dates.end" />
          } @else {
            @if (assetContainer.telemetry) {
              <app-info-panel>No Data</app-info-panel>
            } @else {
              <i class="mdi mdi-spin-x2 mdi-loading"></i>
            }
          }

          <div class="no-print annotation-select">
            <div>Annotation</div>
            <app-select-review-annotation [adminAnnotations]="config().commonAnnotations" (onSelected)="annotationRequested(assetContainer, $event)" />
            <div>
              <span class="cog mdi mdi-cog mr-1" (click)="annotationCogClick()"></span>
              @for (annotation of config().commonAnnotations; track $index) {
                <span class="anno-chip" (click)="annotationQuickButtonClick(assetContainer, annotation)">{{ annotation.text }}</span>
              }
            </div>
          </div>
          <div class="annotation-edit">
            <div class="rag rag-{{ assetContainer.annotation.rag }}" (click)="clickRag(assetContainer)"></div>
            <div>
              <!--<textarea [(ngModel)]="assetContainer.annotation.text" [rows]="1" class="fourd" (change)="annotationChanged()"></textarea>-->
              <div [contentEditable]="true" class="annotation-asset-{{ assetContainer.asset.id }}" #annotationDiv (focusout)="focusOutDIV()">
                {{ assetContainer.annotation.text }}
              </div>
            </div>
          </div>
        </div>
      }
    </section>

    @switch (dialogOpen()) {
      @case ('annotations') {
        <app-dialog header="Manage annotations" [canScroll]="true" (onClose)="dialogOpen.set(null)">
          <table class="table table-small annotations" aria-label="Buildings">
            <thead>
              <tr>
                <th>RAG</th>
                <th>Annotation</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (annotation of config().commonAnnotations; track annotation.id) {
                <tr (click)="manageAnnotation('click', annotation)">
                  <td class="{{ annotation.rag }}">{{ annotation.rag }}</td>
                  <td>{{ annotation.text }}</td>
                  <td><app-button icon="mdi mdi-trash-can" label="Delete" (click)="manageAnnotation('delete', annotation)" /></td>
                </tr>
              }
              <tr>
                <td><app-button [label]="newAnnotationRag" (click)="manageAnnotation('rag')" icon="mdi mdi-rotate-3d-variant" /></td>
                <td><input [(ngModel)]="newAnnotationText" class="fourd" /></td>
                <td><app-button icon="mdi mdi-check" (click)="manageAnnotation('add')" label="Add" /></td>
              </tr>
            </tbody>
          </table>
        </app-dialog>
      }
      @case ('rag') {
        <!-- Auto saves -->
        <app-dialog
          header="Change review from {{ newRAG || 'none' }}"
          [canScroll]="false"
          (onClose)="closeRAGdialog()"
          [dimensions]="{ width: 400, height: 200 }">
          <section class="rag-choices">
            <div class="mt-1">
              <app-button
                label="Green"
                class="mr-1"
                (click)="setRAG('green')"
                icon="mdi mdi-circle"
                [colour]="review().review.rag !== 'green' ? 'green' : ''"
                [isDisabled]="review().review.rag === 'green'" />
              <app-button
                class="mr-1"
                label="Amber"
                (click)="setRAG('amber')"
                icon="mdi mdi-circle"
                [colour]="review().review.rag !== 'amber' ? 'amber' : ''"
                [isDisabled]="review().review.rag === 'amber'" />
              <app-button
                label="Red"
                (click)="setRAG('red')"
                icon="mdi mdi-circle"
                [colour]="review().review.rag !== 'red' ? 'red' : ''"
                [isDisabled]="review().review.rag === 'red'" />
            </div>
            <p class="mt-1">
              When you drag a review PDF and finalise the review, the RAG is set to the highest RAG of the annotations. So if you have a red annotation and a
              green or amber annotation, the RAG will be red.
            </p>
            <!-- <app-toolbar [hasBackground]="false">
              <div class="right">
                <app-button class="ml-1" label="Update" icon="mdi mdi-check" (click)="saveRAG()" />
              </div>
            </app-toolbar> -->
          </section>
        </app-dialog>
      }
      @case ('complete') {
        <app-dialog header="Complete review" [canScroll]="false" (onClose)="dialogOpen.set(null)" [dimensions]="{ width: 720, height: 280 }">
          <section>
            <p class="mt-1">You have annotated all charts, would you like to complete the review?</p>
            <p>
              If you complete the review, the 4D framework will generate the PDF for you and attach it to the buidings documents and mark the review as done.
            </p>
            <p class="red">Please allow around a minute for the process to compelete, it will then be marked as done.</p>
            <app-toolbar [hasBackground]="false">
              <div class="center text-center">
                <app-button label="I'm not ready to complete yet" icon="mdi mdi-close" (click)="dialogOpen.set(null)" [isCTA]="false" />
                <app-button label="Complete Review and attach the PDF for me" icon="mdi mdi-check" (click)="completeReview()" [isCTA]="true" />
              </div>
            </app-toolbar>
            <div class="mt-1 text-center">
              <span class="can-hover" (click)="wantsPDFemailed = !wantsPDFemailed">
                <i class="mdi" [class.mdi-checkbox-blank-outline]="!wantsPDFemailed" [class.mdi-checkbox-marked-outline]="wantsPDFemailed"></i>
                Email me a copy of the PDF
              </span>
            </div>
          </section>
        </app-dialog>
      }
    }
  } @placeholder {}
</article>
