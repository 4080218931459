import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Building } from 'app/classes/building';
import { Asset } from 'app/classes/asset';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpeningHours } from 'app/classes/opening-hours';
import { Gateway } from 'app/classes/gateway';
import { CollectionForAssets } from "app/classes/collection-for-assets";
import { CalendarEntry } from 'app/classes/calendar-entry';
import { StoreService } from './store.service';
import { InsightsService } from './insights.service';
import { BuildingReviewConfigItem, ICommonAnnotation } from 'app/classes/building-review-config';
import { IAssetContainer } from 'app/insights/reviews/insights-review-item/insights-review-item.component';
@Injectable({
  providedIn: 'root'
})
export class BuildingsService {

  API_URL = 'https://0qnxx2zuj2.execute-api.eu-west-2.amazonaws.com/4d/';

  private _buildingList: BehaviorSubject<Building[]> = new BehaviorSubject(null);
  public buildingList: Observable<Building[]> = this._buildingList.asObservable();

  buildings: Building[];

  constructor(private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

  getBuildingForSite(siteId: number): Promise<Building> {
    return new Promise((resolve) => {
      const qs = `a=get_building_for_site&i=${siteId}`;

      return this.get(qs)
        .then(response => {
          const building = new Building(response.building);
          building.openingHours = new OpeningHours(response.hours);
          resolve(building);
        });
    });
  }

  initialiseReview(buildingId: number, start: Date, end: Date, collectionId: number): Promise<any> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=initialise&bid=${buildingId}&start=${this.apiService.dbDate(start)}&end=${this.apiService.dbDate(end)}&cid=${collectionId}`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async getReviewForAutomation(tokenId: string): Promise<any> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/automation?a=automation-get&tokenid=${tokenId}`;

      return this.http
        .get<any>(url)
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async getReviewTelemetryForAutomation(tokenId: string, assetId, start: Date, end: Date, limit = 11000, offset = 0): Promise<any> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/automation?a=automation-telemetry-get&tokenid=${tokenId}`;
      const params = new HttpParams()
        .set('aid', assetId)
        .set('start', this.apiService.dbDate(start))
        .set('end', this.apiService.dbDate(end))
        .set('cacheMaxAge', 86400)
        .set('limit', limit)
        .set('offset', offset);

      return this.http
        .get<any>(url, { params })
        .subscribe(response => {
          resolve({ stats: response.stats, telemetry: response.telemetry.map(t => { return { v: t.v, d: new Date(t.c) } }) });
        });
    });
  }

  async completeReviewForBuilding(buildingId: number, start: Date, emailPDF: boolean): Promise<any> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=complete&bid=${buildingId}&start=${this.apiService.dbDate(start)}&emailpdf=${emailPDF ? 1 : 0}`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async updateReviewRAGForBuilding(buildingId: number, start: Date, rag: 'green' | 'amber' | 'red') {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=update-review-rag&bid=${buildingId}&start=${this.apiService.dbDate(start)}`;

      return this.http
        .post<any>(url, { rag }, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async deleteReviewForBuilding(buildingId: number, start: Date) {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=delete-review&bid=${buildingId}&start=${this.apiService.dbDate(start)}`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async addCommonAnnotation(annotation: { rag: string, text: string }): Promise<ICommonAnnotation> {
    return new Promise(async (resolve, reject) => {
      const url = `${InsightsService.API_URL}reviews/v2?a=add-common-annotation`;

      return this.http
        .post<any>(url, annotation, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async deleteCommonAnnotation(annotationId: number): Promise<ICommonAnnotation> {
    return new Promise(async (resolve, reject) => {
      const url = `${InsightsService.API_URL}reviews/v2?a=delete-common-annotation&caid=${annotationId}`;

      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  getReviewsForBuilding(buildingId: number, offset = 0, limit = 8): Promise<IBuildingReviewListItem[]> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=get-list&bid=${buildingId}&offset=${offset}&limit=${limit}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b.map(item => {

            return { ...item, reviewDate: new Date(item.reviewDate + ' 00:00:00'), reviewEndDate: new Date(item.reviewEndDate + ' 23:59:59') };
          }));
        });
    });
  }

  async updateReviewAnnotationsForBuilding(buildingId: number, start: Date, assets: IAssetContainer[]) {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=update-annotations&bid=${buildingId}&start=${this.apiService.dbDate(start)}`;
      const payload = assets.map(a => {
        return { asset_id: a.asset.id, annotation: { rag: a.annotation.rag, text: a.annotation.text } }
      });
      return this.http
        .post<any>(url, payload, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async updateCollectionForReview(buildingId: number, collectionId: number) {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=update-collection&bid=${buildingId}`;
      const payload = { collection: { id: collectionId } };
      return this.http
        .post<any>(url, payload, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  async updateMonthsAndNotesForReview(buildingId: number, noOfMonths: number, notes: string, nextReviewDate: Date) {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=update-months-and-notes&bid=${buildingId}`;
      const payload = { noOfMonths, notes, nextReviewDate: this.apiService.dbDate(nextReviewDate) };
      return this.http
        .post<any>(url, payload, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  getReviewConfigForBuilding(buildingId: number): Promise<BuildingReviewConfigItem> {
    return new Promise(resolve => {
      const url = `${InsightsService.API_URL}reviews/v2?a=get-config&bid=${buildingId}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(new BuildingReviewConfigItem(b));
        });
    });
  }


  postCalendarEntryForBuilding(buildingId: number, calendarEntry: CalendarEntry): Promise<any> {
    return new Promise((resolve) => {
      const qs = `a=create_calendar_entry&i=${buildingId}`;
      return this.post(calendarEntry.serialise(), qs)
        .then(response => resolve(response));
    });
  };

  /**
   * Get buildings
   */
  getBuildings(qs = ''): Promise<Building[]> {
    const url = `${this.apiService.getUserAPI()}/buildings?${qs}`;

    return this.http
      .get<Building[]>(url, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(buildings => {
        if (!buildings) {
          console.log(`NO_BUILDINGS`, qs, buildings);
          return [];
        }
        this.buildings = buildings.map(building => new Building(building));
        this._buildingList.next(this.buildings);

        return this.buildings;
      });
  }

  /**
   * @param id 
   * @returns 
   */
  getBuilding(buildingId: number): Promise<Building> {
    const url = `${this.apiService.getUserAPI()}/buildings?id=${buildingId}`;

    return this.http
      .get<Building>(url, { headers: this.apiService.getHttpHeaders() })
      .toPromise()
      .then(building => {
        return new Building(building);
      });
  }

  getAssetsForCollections(buildingId: number): Promise<AssetForCollection[]> {
    return new Promise((resolve) => {
      const qs = `a=get_assets&i=${buildingId}`;

      return this.get(qs)
        .then(response => {
          resolve(response.assets.map(a => new AssetForCollection(a)));
        });
    });
  }

  getAssets(buildingId: number): Promise<Asset[]> {
    return new Promise((resolve) => {
      const qs = `a=get_assets&i=${buildingId}`;

      return this.get(qs)
        .then(response => {
          resolve(response.assets.map(a => new Asset(a)));
        });
    });
  }

  updateHours(building: Building) {
    return new Promise((resolve, reject) => {
      this.post({ hours: building.openingHours.serialise() }, `a=update_hours&i=${building.id}`)
        .then(response => {
          resolve(response);
        });
    });
  }

  updateHoursForId(buildingId: number, hours: OpeningHours) {
    return new Promise((resolve, reject) => {
      this.post({ hours: hours.serialise() }, `a=update_hours&i=${buildingId}`)
        .then(response => {
          resolve(response);
        });
    });
  }

  // New get building
  getOne(id: number, qs = ''): Promise<Building> {
    return new Promise((resolve, reject) => {
      qs = `a=get&i=${id}` + (qs ? '&' + qs : '');

      return this.get(qs)
        .then(response => {
          const building = new Building(response.building);
          building.openingHours = new OpeningHours(response.hours);
          if (response.aq) {
            building.aqConfig = response.aq;
          }
          resolve(building);
        });
    });
  }

  getGateways(buildingId: number): Promise<Gateway[]> {
    return new Promise((resolve, reject) => {
      const qs = `a=get_gateways&i=${buildingId}`;

      return this.get(qs)
        .then(response => {
          resolve(response.gateways.map(g => new Gateway(g)));
        });
    });
  }

  getAllCollectionsForUser(typeOfCollection: 'reporting' | 'footfall'): Promise<IgetAllCollectionsForUser> {
    return new Promise((resolve, reject) => {
      const qs = `a=get_all_collections&c=${typeOfCollection}`;

      return this.get(qs)
        .then(response => {
          resolve(response);
        });
    });
  }

  async getCollections(buildingId: number, typeOfCollection: 'reporting' | 'footfall' | 'aq'): Promise<CollectionForAssets> {
    const qs = `a=get_collections&i=${buildingId}&c=${typeOfCollection}`;
    const response = await this.get(qs);

    return new CollectionForAssets(response);
  }

  getAQGatewaysAndCollectionsWithAssets(buildingId: number): Promise<{ gateways: Gateway[], collections: any[] }> {
    return new Promise((resolve, reject) => {
      const qs = `a=get_aq_gateways_assets&i=${buildingId}`;

      return this.get(qs)
        .then(response => {
          const gateways: Gateway[] = response.gateways.map(g => new Gateway(g));

          response.assets.forEach(asset => {
            const gateway = gateways.find(g => g.id === asset.gateway_id);
            gateway.assets.add(new Asset(asset));
          });

          resolve({ gateways, collections: response.collections });
        });
    });
  }

  post(body, qs: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .post<any>(url, body, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }
  get(qs: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  createCollection(buildingId: number, type: 'reporting' | 'footfall', title: string, assets: AssetForCollection[]): Promise<number> {
    return new Promise((resolve) => {
      const qs = `i=${buildingId}&a=create_collection`;
      const payload = {
        title,
        assets: assets.map(a => a.serialise()),
        type
      };
      return this
        .post(payload, qs)
        .then(b => {
          resolve(b.collection_id);
        });
    });
  }
}

export class AssetForCollection extends Asset {

  collectionTitle: string;

  constructor(data: any) {
    super(data);
    this.collectionTitle = data.collectionTitle;
  }

  serialise() {
    return { id: this.id, title: this.collectionTitle };
  }
}

export interface IgetAllCollectionsForUser {
  assets: {
    assetId: number,
    assetTitle: string,
    assetType_id: number,
    buildingId: number,
    buildingTitle: string,
    collectionId: number,
    collectionTitle: string,
    collectionAssetTitle: string,
    gateway_id: string,
    location: string,
    metobsid: number,
    status: string,
    value: string
  }[];
}

export interface IBuildingReviewListItem {
  building_id: number;
  state: string;
  noOfMonths: number;
  createdAt: Date;
  createdBy: { id: number, name: string };
  reviewDate: Date;
  reviewEndDate: Date;
  documentKey: string;
  rag: 'green' | 'amber' | 'red' | null;
}

export interface IBuildingReviewConfigItem {
  building: {
    id: number,
    title: string;
    address: { addressline1: string, addressTown: string }
  };
  collection: { id: number, title: string };
  review: { nextReviewDate: Date };
}
