<section>
	<div class="table">
		<table class="table table-hover">
			<thead>
				<tr>
					<th>Organisation</th>
					<th class="value">Sites</th>
					<th class="value">% Activity</th>
				</tr>
			</thead>

			<tbody>
				<tr *ngFor="let org of orgs" class="rag-{{org.engagementStatus}}" [class.no-activity]="org.activity === 0">
					<td>{{org.title}}</td>
					<td class="value">{{org.siteCount}}</td>
					<td class="value">{{org.activity}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</section>

<section class="left">
	<div class="table org-sites">
		<table class="table table-hover">
			<ng-template ngFor let-org [ngForOf]="orgFilter">
				<tbody class="org" [class.collapsed]="org.isCollapsed">
					<tr (click)="org.isCollapsed = !org.isCollapsed" class="collapsable">
						<th colspan="2"><i class="mdi" [class.mdi-checkbox-blank-circle-outline]="!org.isCompleted"
								[class.mdi-checkbox-marked-circle]="org.isCompleted"></i> {{org.title}} <i class="fa fa-fw pull-right"
								[class.fa-chevron-up]="!org.isCollapsed" [class.fa-chevron-down]="org.isCollapsed"> </i></th>
					</tr>
					<tr class="sub-header">
						<td>Site</td>
						<td class="value">Viewed</td>
					</tr>
					<tr *ngFor="let site of org.sites" class="site" [class.no-views]="site.accessCount === 0">
						<td>{{site.title}}</td>
						<td class="value">{{site.accessCount}}</td>
					</tr>
				</tbody>
			</ng-template>
		</table>
	</div>
</section>

<section class="right">
	<div class="table org-sites">
		<table class="table table-hover">
			<tbody>
				<ng-template ngFor let-site [ngForOf]="siteList">
					<tr class="site" [class.no-views]="!site.isViewed">
						<td title="{{site.path}}">
							<span class="mdi" [class.mdi-checkbox-blank-circle-outline]="!site.isViewed"
								[class.mdi-checkbox-marked-circle]="site.isViewed"></span>
							{{site.title}}
						</td>

						<td pTooltip="{{site.org.title}} - {{site.userName}}" tooltipPosition="bottom"><img class="org"
								src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{site.org.logo}}">
							{{site.accessedAt | amTimeAgo}}</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</section>