<article>
  @defer (when review() && assets()) {
    <section class="page-1">
      <app-insights-as-page1 [building]="review().building" [start]="review().review.dates.start" [end]="review().review.dates.end" />
    </section>

    @for (assetContainer of assets(); track assetContainer.asset.id) {
      <div class="asset asset-{{ assetContainer.asset.id }} mb-1">
        <div class="asset-title"><app-asset-icon [assetTypeId]="assetContainer.asset.assetType_id"></app-asset-icon> {{ assetContainer.asset.title }}</div>
        @if (assetContainer.telemetry?.length) {
          <app-d3-chart
            [asset]="assetContainer.asset"
            [hasToClickToInteract]="true"
            [canAnnotate]="true"
            [canZoom]="false"
            height="200"
            [telemetry]="assetContainer.telemetry"
            [showLegend]="true"
            [minMaxFromData]="true"
            [penWidth]="0.8" />
          <div class="annotation-edit">
            <div class="rag rag-{{ assetContainer.annotation.rag }} mr-1"></div>
            <div>
              <div class="annotation-asset-{{ assetContainer.asset.id }}">
                {{ assetContainer.annotation.text }}
              </div>
            </div>
          </div>
        } @else {
          @if (assetContainer.telemetry) {
            <app-info-panel>No Data</app-info-panel>
          } @else {
            <i class="mdi mdi-spin-x2 mdi-loading"></i>
          }
        }
      </div>
    }
  }
</article>
